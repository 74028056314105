import { jwtDecode } from "jwt-decode";
import emoji from 'emoji-dictionary';

export const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decodedToken: { exp: number } = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    return expirationTime > Date.now(); // Check if the token is still valid
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

export const convertShortcodesToEmojis = (text: string): string => {
  if (!text) return '';
  // Regular expression to match emoji shortcodes
  const shortcodeRegex = /:([a-zA-Z0-9_+-]+):/g;

  return text.replace(shortcodeRegex, (match, shortcode) => {
    const emojiChar = emoji.getUnicode(shortcode);
    return emojiChar || match;
  });
};

export const chunkArray = (array: any[], size: number) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};


export const isVerticalImage = (image: HTMLImageElement): boolean => {
  return image.naturalHeight > image.naturalWidth;
};