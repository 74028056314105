import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProposalState {
    show: boolean;
}

const initialState: ProposalState = {
    show: false
};

const proposalSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        show(state, action: PayloadAction<boolean>) {
            state.show = action.payload;
        }
    },
});

export const { show } = proposalSlice.actions;
export default proposalSlice.reducer;