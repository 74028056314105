import { FC } from 'react';
import './Sidebar.scss';
import logo from '../../assets/images/logo.svg';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

interface SidebarProps { }

const Sidebar: FC<SidebarProps> = () => {
  const expanded = useSelector((state: RootState) => state.sidebar.expanded);

  return (
    <div className={`sidebar-wrapper ${expanded ? 'expanded' : 'collapsed'}`}>
      <div className='logo-wrapper'>
        <Link to="/">
          <img src={logo} alt="Biggenie Logo" className='d-block mx-auto' />
        </Link>
      </div>
    </div>
  )
};

export default Sidebar;
