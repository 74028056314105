import React, { lazy, Suspense } from 'react';
import { SubscriptionProps } from './Subscription';

const LazySubscription = lazy(() => import('./Subscription'));

const Subscription = (props: SubscriptionProps) => (
  <Suspense fallback={null}>
    <LazySubscription {...props} />
  </Suspense>
);

export default Subscription;
