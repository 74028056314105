import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
  expanded: boolean;
}

const initialState: SidebarState = {
  expanded: false
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    expand(state, action: PayloadAction<boolean>) {
      state.expanded = action.payload;
    }
  },
});

export const { expand } = sidebarSlice.actions;
export default sidebarSlice.reducer;