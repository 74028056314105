import React, { lazy, Suspense } from 'react';

const LazyReviewProposal = lazy(() => import('./ReviewProposal'));

const ReviewProposal = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyReviewProposal {...props} />
  </Suspense>
);

export default ReviewProposal;
