import React, { FC, useState } from 'react';
import './Toast.scss';
import ToastB from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { closeToast } from '../../reducers/toast.slice';
import SuccessIcon from '../../assets/images/success_toast_icon.svg';
import ErrorIcon from '../../assets/images/error_toast_icon.svg';
import WarningIcon from '../../assets/images/warning_toast_icon.svg';

type ToastType = 'success' | 'error' | 'warning';

export interface ToastProps {
  msg: string;
  type: ToastType;
  show: boolean;
}

const Toast: FC<ToastProps> = (props: ToastProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const onClose = () => {
    dispatch(closeToast());
  };

  const getIcon = (type: string) => {
    let icon;
    switch (type) {
      case 'success':
        icon = SuccessIcon;
        break;
      case 'warning':
        icon = WarningIcon;
        break;
      default:
        icon = ErrorIcon;
    }

    return <span className='me-2'><img src={icon} alt={`${props.type}-icon`} /></span>
  }

  return (
    <ToastContainer position='top-end'>
      <ToastB className={props.type} show={props.show} onClose={onClose} delay={5000} autohide>
        <ToastB.Header>
          <div>
            {getIcon(props.type)}
            {props.type}
          </div>
        </ToastB.Header>
        <ToastB.Body>{props.msg}</ToastB.Body>
      </ToastB>
    </ToastContainer>
  )
};

export default Toast;
