import config from '../config';
import axios from 'axios';
import { store } from '../store';

interface Header {
    [key: string]: string;
}

type APIURLType = 'BE' | 'AUTH_BE';

class ConnectionHelper {
    private API_URL = config.apiUrl;

    constructor(url?: APIURLType) {
        this.API_URL = this.getURL(url || 'BE');
    }

    getHeaders = (headers?: Header) => {
        const token = store.getState().auth.token || null;
        return {
            Authorization: `Bearer ${token}`,
            ...headers
        }
    };

    private getURL(url: APIURLType) {
        return url === 'AUTH_BE' ? config.authApiUrl : config.apiUrl;
    }

    setAPIURL(url: APIURLType) {
        this.API_URL = this.getURL(url);
    }

    makeRequest(method: string, url: string, body = {}, headers?: Header): Promise<any> {
        const url_ = this.API_URL + url;
        const source = axios.CancelToken.source();
        return axios.request({
            method,
            url: url_,
            data: body,
            cancelToken: source.token,
            headers: this.getHeaders(headers)
        });
    }
}

export default ConnectionHelper;