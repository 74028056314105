import { createBrowserRouter, useNavigate } from "react-router-dom";
import Chat from "./pages/Chat/Chat.lazy";
import Dashboard from "./pages/Dashboard/Dashboard.lazy";
import Login from "./pages/Login/Login.lazy";
import Main from "./pages/Main/Main";
import { useSelector } from "react-redux";
import { RootState } from "./reducers";
import React from "react";
import Subscription from "./pages/Subscription/Subscription.lazy";
import Welcome from "./pages/Subscription/Welcome/Welcome.lazy";
import Plans from "./pages/Subscription/Plans/Plans.lazy";
import UserProfile from "./pages/UserProfile/UserProfile.lazy";
import Proposal from "./pages/Proposal/Proposal.lazy";
import ReviewProposal from "./pages/ReviewProposal/ReviewProposal.lazy";
import { isTokenValid } from "./utils";
import CompanyProfile from "./pages/CompanyProfile/CompanyProfile.lazy";

const withAuthenticationCheck = (WrappedComponent: React.ComponentType) => (props: any) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const token = useSelector((state: RootState) => state.auth.token);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isTokenValid(token) || !isAuthenticated) {
      navigate("/login", { state: { from: window.location.pathname } });
    }
  }, [isAuthenticated, navigate, token]);

  if (!isTokenValid(token) || !isAuthenticated) {
    return null;
  }

  return <WrappedComponent {...props} />;
};

const router = createBrowserRouter([
  {
    path: "/",
    Component: withAuthenticationCheck(Main),
    children: [
      {
        index: true,
        Component: Dashboard
      },
      {
        path: "subscription",
        Component: Subscription,
        children: [
          {
            index: true,
            Component: Welcome
          },
          {
            path: 'plans',
            Component: Plans
          }
        ]
      },
      {
        path: "chat/:id",
        Component: Chat
      },
      {
        path: 'proposal/:id',
        Component: Proposal
      },
      {
        path: "user-profile",
        Component: withAuthenticationCheck(UserProfile)
      },
      {
        path: "company-profile",
        Component: withAuthenticationCheck(CompanyProfile)
      },
      {
        path: "requests",
        Component: withAuthenticationCheck(ReviewProposal)
      }
    ]
  },
  {
    path: "login",
    Component: Login
  }
]);

export default router;
