import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoaderState {
    loading: boolean;
    msg?: string;
}

interface LoaderI {
    loading: number;
    msg?: string;
}

const initialState: LoaderI = {
    loading: 0,
    msg: ''
};

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        isLoading(state, action: PayloadAction<LoaderState>) {
            if (action.payload.loading) {
                state.loading++;
            } else {
                state.loading--;
            }
            if (action.payload.msg) {
                state.msg = action.payload.msg;
            }
        },

        setMessage(state, action: PayloadAction<Partial<LoaderState>>) {
            state.msg = action.payload.msg;
        }
    },
});

export const { isLoading, setMessage } = loaderSlice.actions;
export default loaderSlice.reducer;