import React from 'react';
import './App.scss';
import { RouterProvider } from 'react-router-dom';
import router from './routes';

function App() {
  return (
    <div className="app-wrapper container-fluid p-0">
       <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
    </div>
  );
}

export default App;
