import React, { lazy, Suspense } from 'react';
import { UploadProposalProps } from "./UploadProposal";

const LazyUploadProposal = lazy(() => import('./UploadProposal'));

const UploadProposal = (props: UploadProposalProps) => (
  <Suspense fallback={null}>
    <LazyUploadProposal {...props} />
  </Suspense>
);

export default UploadProposal;
