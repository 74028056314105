import React, { FC } from 'react';
import './Header.scss';
import Logo from '../../assets/images/logo_color.svg';
import LogoutIcon from '../../assets/images/logout_icon.svg';
import MenuIcon from '../../assets/images/menu_icon.svg';
import UserIcon from '../../assets/images/account_circle_icon.svg';
import UserMailIcon from '../../assets/images/user_menu_mail_icon.svg';
import UserPersonIcon from '../../assets/images/user_menu_person_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { logout } from '../../reducers/auth.slice';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { RootState } from '../../reducers';
import { UserIPI } from '../../models/user.model';
import { show } from '../../reducers/proposal.slice';

interface HeaderProps { }
interface menuEntry {
  label: string;
  url: string;
  onClick?: () => void;
}

interface UserMenuI {
  icon: string;
  key: string;
  onClick?: () => void;
}

const Header: FC<HeaderProps> = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<AppDispatch>();

  const menuItems: menuEntry[] = [{
    label: "User Profile",
    url: "/user-profile"
  }, {
    label: "Add Company Profile",
    url: "/company-profile"
  }];

  const items: menuEntry[] = [
    {
      label: "Dashboard",
      url: "/"
    }, {
      label: "Create Proposal",
      url: "",
      onClick: () => {
        dispatch(show(true));
      }
    }, {
      label: "My Subscription",
      url: "/subscription"
    }];

  const userMenu: UserMenuI[] = [{
    icon: UserMailIcon,
    key: 'email'
  }, {
    icon: UserPersonIcon,
    key: 'name'
  }];

  const onLogout = () => {
    dispatch(logout());
  };

  const renderItems = () => {
    return items.map((item, i) => item.url ? <Link className="text-decoration-none text text-body" key={`item-${i}`} to={item.url}>{item.label}</Link> : <div key={`item-${i}`} onClick={item.onClick}>{item.label}</div>);
  };

  return (
    <div className='header-wrapper'>
      <div className='col-auto'>
        <Link to="/">
          < img src={Logo} alt="Logo" className='logo' />
        </Link >
      </div >
      <div className='items-wrapper col'>
        {renderItems()}
        <Dropdown>
          <Dropdown.Toggle variant="transparent" id="menu-dropdown">
            <img className='dropdown-toggle' src={MenuIcon} alt="menu icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {menuItems.map((item: menuEntry, i: number) =>
              <React.Fragment key={`menu-item-${i}`}>
                <Dropdown.Item eventKey={i + 1} as={Link} to={item.url}>{item.label}</Dropdown.Item>
                {(i + 1) !== menuItems.length && <Dropdown.Divider key={`divider-${i}`} />}
              </React.Fragment>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle variant="transparent" id="user-dropdown">
            <img className='dropdown-toggle' src={UserIcon} alt="user icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item disabled active={false} className='greeting'>Welcome</Dropdown.Item>
            <Dropdown.Divider />
            {userMenu.map((item: UserMenuI, i: number) =>
              <Dropdown.Item disabled active={false} key={`user-menu-item-${i}`} eventKey={i + 1}>
                <img src={item.icon} alt={`${item.key}-icon`} />
                <span>{user?.[item.key as keyof UserIPI]}</span>
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
            <Dropdown.Item className='logout-item' onClick={onLogout}>
              <div className='logout-btn pe-auto'>
                <img src={LogoutIcon} alt="Logout icon" />
                Logout
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div >
  )
};

export default Header;
