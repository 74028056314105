import { AxiosError } from "axios";
import { CompanyI, ResourceI } from "../models/company.model";
import { UserI } from "../models/user.model";
import ConnectionHelper from "./connectionHelper.api";

const connectionHelper = new ConnectionHelper();
const PREFIX = '/companies';

export const getResources = async (companyID: string): Promise<ResourceI[] | undefined> => {
    try {
        const resp = await connectionHelper.makeRequest('GET', `${PREFIX}/${companyID}/resources`);
        return resp.data;
    }
    catch (e) {
        console.error("fetching Sign off details: ", e);
    }
};

export const getCompany = async (): Promise<CompanyI | undefined> => {
    try {
        const resp = await connectionHelper.makeRequest('GET', `${PREFIX}/my_company`);
        return resp.data;
    }
    catch (e) {
        console.error("Error fetching company data: ", e);
        throw e;
    }
};

export const getUsersByRole = async (companyID: string, role: string): Promise<UserI[] | undefined> => {
    try {
        const resp = await connectionHelper.makeRequest('GET', `${PREFIX}/${companyID}/users/role/${role.toLowerCase()}`);
        return resp.data;
    }
    catch (e) {
        throw e;
    }
};

export const updateCompany = async (payload: CompanyI): Promise<CompanyI | undefined> => {
    try {
        const resp = await connectionHelper.makeRequest('PUT', `${PREFIX}/`, payload);
        return resp.data;
    }
    catch (e) {
        console.error("Error updating company: ", e);
        throw e;
    }
};

export const addResource = async (company_id: string, payload: ResourceI): Promise<ResourceI | undefined> => {
    try {
        const resp = await connectionHelper.makeRequest('PUT', `${PREFIX}/${company_id}/resources`, payload);
        return resp.data;
    }
    catch (e) {
        console.error("Error updating company resource: ", e);
        throw e;
    }
};