import React, { lazy, Suspense } from 'react';

const LazyCompanyProfile = lazy(() => import('./CompanyProfile'));

const CompanyProfile = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyCompanyProfile {...props} />
  </Suspense>
);

export default CompanyProfile;
