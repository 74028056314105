import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyI } from '../models/company.model';

export interface CompanyState extends CompanyI { };

const initialState: CompanyState = {
  address: "",
  created_at: "",
  description: "",
  email: "",
  id: "",
  name: "",
  phone: "",
  resources: undefined,
  user_id: "",
  website: ""
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany(state: CompanyI, action: PayloadAction<CompanyState>) {
      const { address, created_at, description, email, id, name, phone, resources, user_id, website } = action.payload;
      state.address = address;
      state.created_at = created_at;
      state.description = description;
      state.email = email;
      state.id = id;
      state.name = name;
      state.phone = phone;
      state.resources = resources;
      state.user_id = user_id;
      state.website = website;
    }
  },
});

export const { setCompany } = companySlice.actions;
export default companySlice.reducer;