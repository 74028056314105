import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from '../components/Toast/Toast';

export interface LoaderState extends ToastProps { }

const initialState: LoaderState = {
    msg: '',
    type: 'success',
    show: false
};

type Payload = Omit<LoaderState, "show">;

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast(state: LoaderState, action: PayloadAction<Payload>) {
            const { msg, type } = action.payload;
            state.msg = msg;
            state.type = type;
            state.show = true;
        },
        closeToast(state: LoaderState) {
            state.show = false;
        }
    }
});

export const { showToast, closeToast } = toastSlice.actions;
export default toastSlice.reducer;