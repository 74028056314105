import { combineReducers } from 'redux';
import authReducer from './auth.slice';
import sidebarReducer from './sidebar.slice';
import companyReducer from './company.slice';
import loaderSlice from './loader.slice';
import toastSlice from './toast.slice';
import proposalSlice from './proposal.slice';

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  company: companyReducer,
  loader: loaderSlice,
  toast: toastSlice,
  proposal: proposalSlice
});

export default rootReducer;

// Define RootState type for useSelector
export type RootState = ReturnType<typeof rootReducer>;