import React, { lazy, Suspense } from 'react';

const LazyPlans = lazy(() => import('./Plans'));

const Plans = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPlans {...props} />
  </Suspense>
);

export default Plans;
